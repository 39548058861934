import styles from "./CoreValues.module.scss";
import Link from "next/link";
import { MdChevronRight as ChevRight } from "react-icons/md";
export default function CoreValues() {
  return (
    <section className={styles["CoreValuesSection"]}>
      <h2>Why Choose Magical Trip?</h2>
      <div className={styles["CoreValues"]}>
        <div>
          <svg
            width="40"
            height="32"
            viewBox="0 0 40 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.2915 1.43199L12.4781 20.2101L8.35034 16.0829C6.43908 14.1746 3.34252 14.1746 1.43125 16.0829C-0.480015 17.9852 -0.480015 21.0829 1.43125 22.9912L9.01707 30.571C9.93566 31.4882 11.1772 32.003 12.4751 32.003C13.773 32.003 15.0176 31.4882 15.9332 30.571L38.2106 8.33731C40.1219 6.42903 40.1219 3.33731 38.2106 1.42903C36.2993 -0.479253 33.2028 -0.479253 31.2915 1.42903V1.43199Z"
              fill="#F3E53A"
            />
          </svg>

          <h3>Meet the heart of Magical Trip</h3>
          <p>
            Our exclusive local guides. Elevate your journey with a unique
            travel experience that&apos;s bound to be your trip&apos;s
            highlight!
          </p>
        </div>
        <div>
          <svg
            width="40"
            height="32"
            viewBox="0 0 40 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.2915 1.43199L12.4781 20.2101L8.35034 16.0829C6.43908 14.1746 3.34252 14.1746 1.43125 16.0829C-0.480015 17.9852 -0.480015 21.0829 1.43125 22.9912L9.01707 30.571C9.93566 31.4882 11.1772 32.003 12.4751 32.003C13.773 32.003 15.0176 31.4882 15.9332 30.571L38.2106 8.33731C40.1219 6.42903 40.1219 3.33731 38.2106 1.42903C36.2993 -0.479253 33.2028 -0.479253 31.2915 1.42903V1.43199Z"
              fill="#F3E53A"
            />
          </svg>

          <h3>Experience intimate tours at Magical Trip.</h3>
          <p>
            Connect personally with locals, talented guides, and fellow
            adventurers for spontaneous, script-free journeys enriched by
            friendly local insights.
          </p>
        </div>
        <div>
          <svg
            width="40"
            height="32"
            viewBox="0 0 40 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M31.2915 1.43199L12.4781 20.2101L8.35034 16.0829C6.43908 14.1746 3.34252 14.1746 1.43125 16.0829C-0.480015 17.9852 -0.480015 21.0829 1.43125 22.9912L9.01707 30.571C9.93566 31.4882 11.1772 32.003 12.4751 32.003C13.773 32.003 15.0176 31.4882 15.9332 30.571L38.2106 8.33731C40.1219 6.42903 40.1219 3.33731 38.2106 1.42903C36.2993 -0.479253 33.2028 -0.479253 31.2915 1.42903V1.43199Z"
              fill="#F3E53A"
            />
          </svg>

          <h3>
            Unlock genuine local experiences with Magical Trip&apos;s Guides.{" "}
          </h3>
          <p>
            Escape tourist traps, immerse in authentic neighborhood activities,
            and forge connections that turn strangers into friends. Travel like
            a local, return with lasting memories.
          </p>
        </div>
      </div>
      <div className={styles.AboutBtn}>
        <Link href="/about-us" target="_blank">
          Read About MagicalTrip {""}
          <ChevRight
            color="#f44336"
            size={20}
            style={{
              position: "relative",
              top: 5,
            }}
          />
        </Link>
      </div>
    </section>
  );
}
