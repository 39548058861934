import Link from "next/link";
import styles from "./GuideCarousel.module.scss";

import { Guide, Location } from "../../../../graphql/operations";
import { CloudinaryImage } from "../../../Atoms/Cloudinary";

interface GuideCarouselProps {
  location?: Location;
  guides: Guide[];
}

export default function GuideCarousel({
  guides,
  location,
}: GuideCarouselProps) {
  return (
    <>
      {guides.length > 0 ? (
        <>
          <h3
            className="UIT UITSLL UITW1 UITC0"
            style={{
              marginTop: 60,
            }}
          >
            {!location
              ? "Travel Deeper with Local Guides"
              : `Explore ${location.translation.name} with MagicalTrip's Local Guides`}
          </h3>
          <p
            style={{
              marginBottom: 40,
              fontSize: 14,
            }}
          >
            {!location
              ? "Connect with the local community on a personal level by getting to know our talented guides!"
              : `Learn about their personal stories, and find out how you can explore ${location.translation.name} together`}
          </p>
          <div className={styles.GuideSlider}>
            {guides.map((guide, index) => {
              return (
                <div key={index} className={styles.GuideItem}>
                  <div className={styles.GuideImageContainer}>
                    <CloudinaryImage src={guide.profilePic} />
                  </div>
                  <h4>{guide.nickName}</h4>
                  <Link href={`/guide-profile/${guide.id}`} target="_blank">
                    <span>See More</span>
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </>
  );
}
