import { gql, useQuery } from "@apollo/client";
import Loader from "../../Atoms/Loader";
import FeaturedBanner from "./FeaturedBanner";
import styles from "./FeaturedBanners.module.scss";

interface FeaturedProps {
  showHome?: boolean;
  locationId?: string;
}

export default function FeaturedBanners({
  showHome,
  locationId,
}: FeaturedProps) {
  const { loading, error, data } = useQuery(GET_FEATURED_BANNERS, {
    variables: {
      showHome,
      locationId,
    },
    fetchPolicy: "cache-and-network",
  });
  if (loading) {
    return <Loader />;
  }
  if (error || data.featuredBanners.length === 0) {
    return <></>;
  }

  return (
    <div className="AppLayout">
      <h2 className="UIT UITSLL UITW1 UITC0 HomeCityHeader">
        Enhance your trip with the way you like it
      </h2>
      <div className={styles.FeaturedBannersContainer}>
        {data.featuredBanners.map((banner, index) => {
          return <FeaturedBanner key={index} banner={banner} />;
        })}
      </div>
    </div>
  );
}

export const GET_FEATURED_BANNERS = gql`
  query FeaturedBanners($showHome: Boolean, $locationId: ID) {
    featuredBanners(showHome: $showHome, locationId: $locationId) {
      title
      imageUrl
      pageUrl
      ctaText
    }
  }
`;
