import { IoMdArrowForward } from "react-icons/io";
import { CloudinaryImage } from "../../Atoms/Cloudinary";
import styles from "./FeaturedBanners.module.scss";

interface FeaturedBannersProps {
  banner: {
    title: string;
    imageUrl: string;
    pageUrl: string;
    ctaText: string;
  };
}
export default function FeaturedBanner({ banner }: FeaturedBannersProps) {
  const { pageUrl, imageUrl, ctaText, title } = banner;
  return (
    <div className={styles.FeaturedBanner}>
      <a href={pageUrl} target="_blank">
        <div className={styles.BackDrop} />

        <p className={styles.FeaturedTitle}>{title}</p>
        <p className={styles.CtaText}>
          {ctaText}{" "}
          <IoMdArrowForward
            style={{
              position: "relative",
              top: 3,
              left: 3,
              fontSize: 16,
              borderRadius: "50%",
              border: "2px solid #FFF",
            }}
          />
        </p>
        <CloudinaryImage
          src={imageUrl}
          transformations={["w_360", "h_200", "c_fill"]}
        />
      </a>
    </div>
  );
}
