import { differenceInCalendarDays, format } from "date-fns";
import ja from "date-fns/locale/ja";
import add from "date-fns/add";
import sub from "date-fns/sub";
import isBefore from "date-fns/isBefore";
import parse from "date-fns/parse";

function stringToDate(dateString) {
  return parse(dateString, "yyyy-MM-dd", new Date());
}

export function isSameDay(a: number | Date, b: number | Date) {
  return differenceInCalendarDays(a, b) === 0;
}

export const formateDateRegExp = new RegExp(
  "(19[0-9]{2}|20[0-9]{2})/(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])"
);
export function testFormatedDate(input: string): boolean {
  return formateDateRegExp.test(input);
}
export const formatTimeRegExp = new RegExp("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$");
export function testFormatedTime(input: string): boolean {
  return formatTimeRegExp.test(input);
}

export const checkTimeIsMorning = (time: string): boolean => {
  if (!testFormatedTime(time)) return false;
  const hour = Number(time.split(":")[0] ?? 0);
  return hour >= 5 && hour <= 10;
};

export const checkTimeIsDayTime = (time: string): boolean => {
  if (!testFormatedTime(time)) return false;
  const hour = Number(time.split(":")[0] ?? 0);
  return hour >= 11 && hour <= 15;
};

export const checkTimeIsNight = (time: string): boolean => {
  if (!testFormatedTime(time)) return false;
  const hour = Number(time.split(":")[0] ?? 0);
  return hour >= 16 || hour <= 4;
};

export function formatDate(a: string | number | Date) {
  if (!a) {
    return "";
  }
  if (a instanceof Date === false) {
    let dateString = a;
    let dateObject = stringToDate(dateString);
    return format(dateObject, "yyyy-MM-dd", { locale: ja });
  }

  let date = safeDate(a);
  if (!date) {
    return "";
  }
  return format(date, "yyyy-MM-dd", { locale: ja });
}

export function formatTime(a: string) {
  return format(new Date(a.replace(/-/g, "/")), "HH:mm:ss", { locale: ja });
}

export function formatTimeM(a: string) {
  return format(new Date(a.replace(/-/g, "/")), "HH:mm", { locale: ja });
}

export function addDuration(
  a: number | Date,
  b: {
    months?: number;
    days?: number;
    years?: number;
    weeks?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
  }
) {
  return add(a, b);
}
export function subDuration(
  a: number | Date,
  b: {
    days: number;
    years?: number;
    months?: number;
    weeks?: number;
    hours?: number;
    minutes?: number;
    seconds?: number;
  }
) {
  return sub(a, b);
}
export function uniFormatDate(a: number | Date, b: string) {
  return format(a, b, { locale: ja });
}
export function beforeDate(a: number | Date, b: number | Date) {
  return isBefore(a, b);
}

export const safeDate = (val: any): Date | undefined => {
  try {
    const date = new Date(val);
    return !date.getTime() ? undefined : date;
  } catch {
    return undefined;
  }
};
