import Link from "next/link";
import { useEffect, useMemo, useState } from "react";
import { FaRegClock } from "react-icons/fa";
import { Product } from "../../../graphql/operations";
import { addDuration, formatDate } from "../../../utils/calendarUtils";
import { useAnalyticsTracker } from "../../Atoms/Analytics";
import { useSession } from "../../Atoms/AppSession";
import { CloudinaryImage } from "../../Atoms/Cloudinary";
import Price from "./Price";
import styles from "./ProductCard.module.scss";

const posterSize = {
  width: 210,
  height: 319,
};

type ReviewRatingProps = Pick<Product, "reviews">;

export function ReviewRating({ reviews }: ReviewRatingProps) {
  if (!reviews.avgStars) {
    return null;
  }

  const displayRating = reviews.avgStars.toFixed(2);

  return (
    <>
      <span className={styles.ProductCardRating}>
        ★ <b style={{ color: "#000" }}>{displayRating}</b>
      </span>{" "}
      ({reviews.totalRecords})
    </>
  );
}

type CityTourCardProps = Pick<
  Product,
  | "id"
  | "translation"
  | "duration"
  | "poster"
  | "tags"
  | "availability"
  | "price"
  | "reviews"
> & { parameter?: string };
export default function CityTourCard({
  id,
  reviews,
  price,
  poster,
  tags,
  translation,
  duration,
  parameter,
  availability,
}: CityTourCardProps) {
  const tracker = useAnalyticsTracker();
  const session = useSession();
  const { countryCode } = session;
  const refParam = parameter !== undefined ? `?ref=${parameter}` : "";
  const today = useMemo(() => new Date(), []);
  const tomorrow = addDuration(today, { days: 1 });
  const [isTourAvailableTommorow, setIsTourAvailableTommorow] = useState(false);
  const [isTourAvailableToday, setIsTourAvailableToday] = useState(false);

  useEffect(() => {
    setIsTourAvailableTommorow(
      availability?.filter(
        (a) => a.date === formatDate(tomorrow) && a.status === "AVAILABLE"
      ).length > 0 && countryCode === "Asia/Tokyo"
    );
    setIsTourAvailableToday(
      availability?.filter(
        (a) => a.date === formatDate(today) && a.status === "AVAILABLE"
      ).length > 0 && countryCode === "Asia/Tokyo"
    );
  }, [availability, countryCode, tomorrow, today]);

  return (
    <div
      className={styles.ProductCard}
      onClick={() =>
        tracker.trackEvent({
          category: "onpage",
          action: "click-poster",
          label: `Id-${id}`,
        })
      }
    >
      <Link href={`/product/${id}${refParam}`}>
        <div className={styles.ProductCardContainer}>
          <div className={styles.ProductCardImage}>
            <CloudinaryImage
              src={poster}
              transformations={[
                `w_${posterSize.width}`,
                `h_${posterSize.height}`,
              ]}
            />
            {isTourAvailableTommorow && !isTourAvailableToday && (
              <div className={styles.PosterAvailabilityLabel}>
                Available Tomorrow
              </div>
            )}

            {isTourAvailableToday && (
              <div className={styles.PosterAvailabilityLabel}>
                Available <span style={{ color: "yellow" }}>Today</span>
              </div>
            )}
          </div>
          <div className={styles.ProductCardContent}>
            <div className={styles.CityTourCardDetails}>
              {reviews?.avgStars && reviews.totalRecords > 0 && (
                <div className={styles.ProductCardReviewRating}>
                  <ReviewRating reviews={reviews} />{" "}
                </div>
              )}
              <h3 className={styles.ProductCardName}>{translation?.name}</h3>
              <p>{translation?.headline}</p>

              {tags && tags.length > 0 && (
                <>
                  <span className={styles.ProductCardTag}>
                    #{tags[0].translation?.name}
                  </span>
                  {tags.length > 1 && (
                    <span className={styles.ProductCardTag}>
                      #{tags[1].translation?.name}
                    </span>
                  )}
                </>
              )}
            </div>

            {!isTourAvailableToday && isTourAvailableTommorow && (
              <div className={styles.ProductCardAvailabilityLabel}>
                Available Tomorrow
              </div>
            )}

            {isTourAvailableToday && (
              <div className={styles.ProductCardAvailabilityLabel}>
                Available <span style={{ color: "yellow" }}>Today</span>
              </div>
            )}

            <div className={styles.DurationPrice}>
              <div className={styles.DDContainer}>
                {reviews?.avgStars && reviews.totalRecords > 0 && (
                  <span className={styles.ProductCardReviewRating}>
                    <ReviewRating reviews={reviews} />{" "}
                  </span>
                )}
                <span>
                  <FaRegClock
                    color="#F33F3A"
                    style={{ position: "relative", top: 2, marginRight: 5 }}
                  />
                  {formatDuration(duration)}
                </span>
              </div>

              {price && <Price id={id} price={price} />}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

function formatDuration(durationInMinutes: number) {
  const durationInHours = durationInMinutes / 60;
  const isExact = durationInMinutes % 60 === 0;
  const formatted = isExact ? durationInHours : durationInHours.toFixed(1);

  return `${formatted} hours`;
}
